"use client";
import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  Drawer,
  Radio,
  Col,
  message,
  Row,
  Typography,
} from "antd";
import SizeChartDrawer from "../SizeChart/SizeChartDrawer/SizeChartDrawer";
import "./ProductSizeBottomDrawer.scss";
const ProductSizeBottomDrawer = (props) => {
  console.log(props?.ProductDetail?.options);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSizeChart, setOpenSizeChart] = useState(false);
  const onClose = () => {
    props?.setOpenDrawer(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeValue = (option) => {
    let value = { ...props?.selectedValueFromOptions };
    value.option1 = option;
    props?.setSelectedValueFromOptions({ ...value });
  };

  const handleSubmitSize = () => {
    // if (props?.selectedValueFromOptions?.option1 === "-9346535324534") {
    //   message.error("Please select a size.");
    // } else {
    //   onClose();
    props?.addProduct();
    props?.setViewBagItemsState(true);
    props?.setaddToCartLoader(true);
    // }
  };

  useEffect(() => {
    let value = { ...props?.SelectedVariant };
    value.option1 = "-9346535324534";
    props?.setSelectedValueFromOptions({ ...value });
  }, [props?.openDrawer]);

  return (
    <div className="product-size-bottom-drawer-main" style={{ zIndex: 1 }}>
      <Drawer
        style={{ zIndex: 1 }}
        className="product-size-bottom-drawer-main"
        title="Select Size"
        placement="bottom"
        width={500}
        height={227}
        onClose={onClose}
        open={props?.openDrawer}
        // extra={
        //   <Space>
        //     <div className="size-chart-heading">
        //       <SizeChartDrawer
        //         ProductDetail={props?.ProductDetail}
        //         SelectedVariant={props?.SelectedVariant}
        //         setSelectedVariant={props?.setSelectedVariant}
        //         SizeChart={props?.props?.ProductDetail?.size_chart}
        //         availableVariants={props?.availableVariants}
        //         ProductQuantity={props?.ProductQuantity}
        //         setProductQuantity={props?.setProductQuantity}
        //         viewBagItemsState={props?.viewBagItemsState}
        //         setViewBagItemsState={props?.setViewBagItemsState}
        //         userAgent={props?.userAgent}
        //         LineItemAddons={props?.LineItemAddons}
        //         setLineItemAddons={props?.setLineItemAddons}
        //         openDrawer={props?.openDrawer}
        //         setOpenDrawer={props?.setOpenDrawer}
        //         showDrawersizechart={props?.showDrawer}
        //         addProduct={props?.addProduct}
        //         pushedVariants={props?.pushedVariants}
        //         setPushedVariants={props?.setPushedVariants}
        //         handleOptionChange={props?.handleOptionChange}
        //         addToCartLoader={props?.addToCartLoader}
        //         setaddToCartLoader={props?.setaddToCartLoader}
        //         selectedValueFromOptions={props?.selectedValueFromOptions}
        //         setSelectedValueFromOptions={props?.setSelectedValueFromOptions}
        //         handleProductDiscountedPrice={
        //           props?.handleProductDiscountedPrice
        //         }
        //         handleProductOrignalPrice={props?.handleProductOrignalPrice}
        //         closeDrawerSize={onClose}
        //         productBottomDrawer
        //       />
        //     </div>
        //   </Space>
        // }
      >
        <div>
          <div className="custom-radio-group">
            {/* {props?.props?.ProductDetail?.variants.map((item, index) => {
              if (item?.variant_detail?.sold_out) {
                return (
                  <div key={index}>
                    <button
                      className={`option ${"button-sold-out-drawer"}`}
                      type="button"
                    >
                      {item?.option1}
                    </button>
                  </div>
                );
              } 
              else { */}

            <Col span={24} style={{ overflowY: "auto" }}>
              {console.log("props?.ProductDetail---->>", props?.ProductDetail)}
              {props?.ProductDetail?.options?.length && (
                <div className="swatch-container">
                  {props?.ProductDetail.options.map((option, index) => (
                    <div
                      className={`swatch-product swatch-type-${option.name}`}
                      key={index}
                    >
                      {console.log("option.values---->>", option.values)}
                      {!option.values.includes("Default") && (
                        <label className="swatch-label-product">
                          {option.name}:
                        </label>
                      )}
                      {option.name === "Colors" ||
                      option.name === "colors" ||
                      option?.name === "Colour" ? (
                        <ul className="swatch-item-list swatch-color">
                          {option.values.length > 0
                            ? option.values.split(",").map((value, index1) => (
                                <li
                                  key={index1}
                                  className={`swatch-element ${
                                    props?.activeSwatches[index] === value
                                      ? "active"
                                      : "uppercase"
                                  } ${
                                    props?.soldoutOptionValuesArr.includes(
                                      value
                                    )
                                      ? "soldout"
                                      : "available"
                                  }`}
                                  style={{
                                    backgroundColor:
                                      props?.activeSwatches[index] === value
                                        ? props?.mainColor
                                        : "",
                                  }}
                                  data-value={value}
                                  onClick={() =>
                                    props?.handleSwatch(index, value)
                                  }
                                >
                                  {/* {value} */}
                                  <span
                                    style={{
                                      position: "relative",
                                      borderRadius: "50px",
                                      border: "1px solid gray",
                                      backgroundColor: value
                                        ?.toLowerCase()
                                        .trim()
                                        .replace(/\s+/g, ""),
                                    }}
                                  >
                                    {" "}
                                  </span>
                                  {props?.activeSwatches[index] === value && (
                                    // <img src={NikeTick.src} />
                                    <DoneIcon
                                      style={{
                                        color:
                                          value?.toLowerCase() === "white"
                                            ? "black"
                                            : "",
                                      }}
                                    />
                                  )}
                                </li>
                              ))
                            : null}
                        </ul>
                      ) : !option.values.includes("Default") ? (
                        <ul className="swatch-item-list">
                          {option.values.length > 0
                            ? option.values.split(",").map((value, index1) => (
                                <li
                                  key={index1}
                                  className={`swatch-element ${
                                    props?.activeSwatches[index] === value
                                      ? "active"
                                      : "uppercase"
                                  } ${
                                    props?.soldoutOptionValuesArr.includes(
                                      value
                                    )
                                      ? "soldout"
                                      : "available"
                                  }`}
                                  style={{
                                    backgroundColor:
                                      props?.activeSwatches[index] === value
                                        ? props?.mainColor
                                        : "",
                                  }}
                                  data-value={value}
                                  onClick={() =>
                                    props?.handleSwatch(index, value)
                                  }
                                >
                                  {value}
                                </li>
                              ))
                            : null}
                        </ul>
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            </Col>

            {/* // return (
                //   <div
                //     key={index}
                //     onClick={() => {
                //       props?.handleOptionChange(item?.option1);
                //       handleChangeValue(item?.option1);
                //     }}
                //   >
                //     <button
                //       className={`option ${
                //         props?.selectedValueFromOptions?.option1 ===
                //         item?.option1
                //           ? "button selected"
                //           : "button"
                //       }`}
                //       type="button"
                //     >
                //       {item?.option1}
                //     </button>
                //   </div>
                // );
            //   }
            // })} */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              disabled={props?.productSoldout}
              type="primary"
              onClick={() => {
                handleSubmitSize();
              }}
              style={{
                width: "184px",
                height: "33px",
                textTransform: "uppercase",
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              DONE
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default ProductSizeBottomDrawer;
