//? Library Imports ------------------------------------------------------------>
import { Switch } from "antd";
import Image from "next/image";
import { useState } from "react";

//? Assets --------------------------------------------------------------------->
import "./SizeChartTable.scss";
import Tape from "../../../../../assets/images/SizeChart/sizechart.svg";

export default function SizeChartTable({
  sizeTable,
  selectedVariant,
  isMobile,
}) {
  //? States --------------------------------------------------------------------->
  const [checked, isChecked] = useState(false);
  const [converter, setConverter] = useState(1);

  var colors = [];
  for (var i = 0; i < sizeTable?.length; i++) {
    colors.push({
      border_color: sizeTable[i]?.border_color,
      grid_bg_color: sizeTable[i]?.grid_bg_color,
      grid_header_color: sizeTable[i]?.grid_header_color,
      grid_text_color: sizeTable[i]?.grid_text_color,
      header_bg_color: sizeTable[i]?.header_bg_color,
    });
  }

  function getSize(value) {
    const defaultsize = value.split();
    return defaultsize[1];
  }

  function convertToInch() {
    if (checked === false) {
      setConverter(2.54);
      isChecked(true);
      return;
    } else {
      setConverter(1.0);
      isChecked(false);
      return;
    }
  }

  return (
    <div className="sizechart-container">
      {sizeTable?.length > 0 &&
        sizeTable?.map(
          ({ title, chart, first_column_heading, first_row_heading }, key) => {
            return (
              <div className="sizechart border-line-20" key={key}>
                <div
                  className="size-title"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "19px",
                    // fontSize: "15px",
                  }}
                >
                  <h3
                    className={`${
                      isMobile ? "font14Bold" : "font16Bold"
                    } "title"`}
                  >
                    Size Guide is Given Below:
                  </h3>
                  <Switch
                    checkedChildren="i"
                    unCheckedChildren="cm"
                    onChange={convertToInch}
                  />
                </div>
                <div className="table-container border-line-20">
                  <table
                    className="table"
                    style={{ borderColor: colors[key]?.border_color }}
                  >
                    <tbody
                      className="table-body"
                      style={{
                        backgroundColor: colors[key]?.grid_bg_color,
                        color: colors[key]?.grid_text_color,
                      }}
                    >
                      {chart?.map((tablerows, index) => (
                        <tr
                          className="table-row"
                          key={index}
                          style={{ borderBottom: "1px solid #F1F1F1" }}
                        >
                          <td
                            className="radio-column"
                            style={{
                              width: "50px",
                              // borderRight: "1px solid #F1F1F1",
                            }}
                          >
                            {index === 0 ? (
                              <Image src={Tape} alt="Measuring Tape" />
                            ) : (
                              <input
                                type="radio"
                                name={`radio_${key}`}
                                className="radio-check"
                                defaultChecked={index === 1}
                                style={{ width: "18px", height: "18px" }}
                              />
                            )}
                          </td>
                          {tablerows?.values?.map(({ column }, indexj) => (
                            <td
                              className={`${
                                (indexj === 0 && first_column_heading) ||
                                (index === 0 && first_row_heading)
                                  ? `${isMobile ? "font14Bold" : "font16Bold"}`
                                  : `${
                                      isMobile ? "font14Light" : "font16Light"
                                    }`
                              }`}
                              style={{
                                // borderRight: "1px solid #F1F1F1",
                                width: indexj === 0 ? "180px" : "141px",
                                height: index === 0 ? "44px" : "44px",
                                backgroundColor:
                                  index === 0
                                    ? colors[key].header_bg_color
                                    : null,
                                color:
                                  index === 0
                                    ? colors[key].grid_header_color
                                    : null,
                              }}
                              key={indexj}
                            >
                              {indexj === 0 || index === 0
                                ? column
                                : (Number(column) * converter).toFixed(0)}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          }
        )}
    </div>
  );
}
