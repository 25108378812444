"use client";
//? Library Imports ------------------------------------------------------------>
import NextImage from "next/image";
import { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Image,
  InputNumber,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import Cookies from "js-cookie";
import "../../ProductDetailPanel/ProductDetailPanel.scss";

//? Assets --------------------------------------------------------------------->
import "./SizeChartDrawer.scss";
// import SizeChart from "../SizeChart";
import SizeChartTable from "../SizeChartTable/SizeChartTable";
import img from "../../../../../assets/images/SizeChart/femalebody.svg";
import btnlogo from "../../../../../assets/images/SizeChart/drawer_button.svg";
import { removeCommasAndConvertToNumber } from "../../../../../functions/HelperFunctions/index";
import DynamicButton from "@/components/shared/Buttons/DynamicButton/DynamicButton";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { RedoOutlined } from "@ant-design/icons";
import ProductSizeBottomDrawer from "../../ProductSizeBottomDrawer/ProductSizeBottomDrawer";
import SizeChartBottomStitchedDrawer from "../../ProductSizeBottomDrawer/SizeChartBottomStitchedDrawer";
import SizeChartBottomUnStitchedDrawer from "../../ProductSizeBottomDrawer/SizeChartBottomUnStitchedDrawer";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;
export default function SizeChartDrawer({
  userAgent,
  SizeChart,
  ProductDetail,
  SelectedVariant,
  setSelectedVariant,
  productBottomDrawer,
  closeDrawerSize,
  availableVariants,
  ProductQuantity,
  setProductQuantity,
  viewBagItemsState,
  setViewBagItemsState,
  LineItemAddons,
  setLineItemAddons,
  openDrawer,
  setOpenDrawer,
  showDrawersizechart,
  addProduct,
  pushedVariants,
  setPushedVariants,
  handleOptionChange,
  addToCartLoader,
  setaddToCartLoader,
  selectedValueFromOptions,
  setSelectedValueFromOptions,
  // handleProductDiscountedPrice,
  handleProductOrignalPrice,
  isSizeChartOpen,
  setIsSizeChartOpen,
  isPret,
}) {
  //? States --------------------------------------------------------------------->
  const [open, setOpen] = useState(false);
  // const [addToCartLoader, setaddToCartLoader] = useState(false);
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const { isMobile, isTablet, isDesktop } = userAgent || {};

  const handleProductDiscountedPrice = () => {
    return SelectedVariant?.variant_detail?.discounted_price;
  };

  // const [size, setSize] = useState(800);
  const defaultCurrency = defaultCurrencyFromCookies;

  //? Functions ------------------------------------------------------------------>
  const quantityIncrement = () => {
    if (ProductDetail?.quantity_limit) {
      if (ProductQuantity < ProductDetail?.quantity_limit) {
        setProductQuantity(ProductQuantity + 1);
      } else {
        message.error("Maximum quantity exceeded");
      }
    } else if (!ProductDetail?.quantity_limit) {
      if (ProductQuantity < 5) {
        setProductQuantity(ProductQuantity + 1);
      } else {
        message.error("Maximum quantity exceeded");
      }
    } else {
      message.error("Maximum quantity exceeded");
    }
    // setProductQuantity(ProductQuantity + 1);
  };

  const quantityDecrement = () => {
    if (ProductQuantity > 1) {
      setProductQuantity(ProductQuantity - 1);
    }
  };
  const showDrawer = () => {
    setIsSizeChartOpen(true);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setIsSizeChartOpen(false);
  };

  const handlePercentageOff = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      SelectedVariant
        ? SelectedVariant?.variant_detail?.original_price
        : availableVariants?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      SelectedVariant
        ? SelectedVariant?.variant_detail?.discounted_price
        : availableVariants?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return ""; // Return an empty string or handle edge cases where the percentage cannot be calculated
  };

  const defaultCurrencyName = Cookies.get("defaultCurrencyName");

  // console.log(SizeChart[0]?.chart[2]?.values[0]?.column?.split(" "));
  // const handleProductDiscountedPrice = () => {
  //   // .toFixed(2)
  //   {
  //     return removeCommasAndConvertToNumber(
  //       SelectedVariant?.variant_detail?.discounted_price
  //     ).toLocaleString();
  //   }
  // };
  return (
    // <div className="SizeChartDrawer">
    //   <>
    <Col>
      {!productBottomDrawer ? (
        <Button
          icon={<NextImage src={btnlogo} alt="sizechart" />}
          onClick={showDrawer}
          style={{
            display: "flex",
            alignItems: "center",
            borderColor: "white",
            justifyContent: "center",
            padding: "0px",
            marginRight: "10px",
          }}
        >
          Size Chart
        </Button>
      ) : (
        <p
          className="size-chart-heading"
          onClick={() => {
            closeDrawerSize();
            setTimeout(() => {
              showDrawer();
            }, 400);
          }}
        >
          Size Chart
        </p>
      )}
      <Drawer
        style={{ zIndex: 500 }}
        open={open}
        title="Size chart"
        width={800}
        placement="right"
        onClose={onClose}
        className="SizeChartDrawer"
      >
        {/* Product Detail */}
        <Col
          style={{ padding: "15px 0px 15px 36px", backgroundColor: "white" }}
        >
          <Row gutter={10} style={{ maxHeight: isDesktop ? "220px" : "" }}>
            {/* Product Information */}
            <Col flex={isDesktop ? "0 0 70%" : "0 0 65%"}>
              {/* Product Title */}
              <Col className="marginBottom5">
                <Text className={isMobile ? "font14Bold" : "font16Bold"}>
                  {ProductDetail?.title}
                </Text>
              </Col>
              <Col className="marginBottom10">
                <Text className={isMobile ? "font13Light" : "font15Light"}>
                  {ProductDetail?.seo_description}
                </Text>
              </Col>
              {/* Product Price */}

              {SelectedVariant ? (
                removeCommasAndConvertToNumber(
                  SelectedVariant?.variant_detail?.original_price
                ) >
                removeCommasAndConvertToNumber(
                  SelectedVariant?.variant_detail?.discounted_price
                ) ? (
                  <Col className="price-container marginBottom5">
                    <Col span={24} className="padding0">
                      <Text className={isMobile ? "font14Bold" : "font16Bold"}>
                        {defaultCurrencyName} {handleProductDiscountedPrice()}
                      </Text>
                    </Col>
                    <Row>
                      <Col className="orignal-price">
                        <Text
                          className={isMobile ? "font14" : "font16"}
                          style={{
                            color: "#606060",
                            textDecoration: "line-through",
                          }}
                        >
                          {defaultCurrencyName} {handleProductOrignalPrice()}
                        </Text>
                      </Col>

                      <Col className="marginLeft5">
                        <Text
                          style={{
                            fontSize: "16px",
                            color: "#EA462F",
                            backgroundColor: "#FFEFF1",
                          }}
                        >
                          {handlePercentageOff()}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Text className={isMobile ? "font14Bold" : "font16Bold"}>
                      {defaultCurrencyName} {handleProductDiscountedPrice()}
                    </Text>
                  </Col>
                )
              ) : removeCommasAndConvertToNumber(
                  availableVariants?.variant_detail?.original_price
                ) >
                removeCommasAndConvertToNumber(
                  availableVariants?.variant_detail?.discounted_price
                ) ? (
                <Col className="price-container marginBottom5">
                  <Col span={24} className="padding0">
                    <Text className={isMobile ? "font14Bold" : "font16Bold"}>
                      {defaultCurrencyName}{" "}
                      {availableVariants?.variant_detail?.discounted_price}
                    </Text>
                  </Col>
                  <Row>
                    <Col className="orignal-price">
                      <Text
                        className={isMobile ? "font14" : "font16"}
                        style={{
                          color: "#606060",
                          textDecoration: "line-through",
                        }}
                      >
                        {defaultCurrencyName}{" "}
                        {availableVariants?.variant_detail?.original_price}
                      </Text>
                    </Col>

                    <Col className="marginLeft5">
                      <Text
                        style={{
                          fontSize: "16px",
                          color: "#EA462F",
                          backgroundColor: "#FFEFF1",
                        }}
                      >
                        {handlePercentageOff()}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col span={24} className="marginBottom5">
                  <Text className={isMobile ? "font14Bold" : "font16Bold"}>
                    {defaultCurrencyName}{" "}
                    {availableVariants?.variant_detail?.discounted_price}
                  </Text>
                </Col>
              )}

              {/* Delivery Date Expected*/}

              {ProductDetail?.delivery_date ? (
                <Col style={{ marginTop: "13px" }}>
                  <Text className={isMobile ? "font12Light" : "font14Light"}>
                    {`Delivery Date Expected: `}
                    <span
                      className={isMobile ? "font12Bold" : "font14Bold"}
                      style={{ fontWeight: "600" }}
                    >
                      {ProductDetail?.delivery_date}
                    </span>
                  </Text>
                </Col>
              ) : null}
            </Col>
            {/* Product Image */}
            {ProductDetail?.images?.length ? (
              <Col
                flex={isDesktop ? "0 0 27%" : "0 0 30%"}
                style={{ textAlign: "end" }}
              >
                <Image
                  width={isMobile ? "100%" : "auto%"}
                  height={isMobile ? "auto" : "80%"}
                  alt="sizechart"
                  src={ProductDetail?.images[0]?.cdn_link}
                  preview={false}
                />
              </Col>
            ) : null}
          </Row>
        </Col>

        {/* Size Chart Table */}
        <Col style={{ backgroundColor: "white", padding: "0px 12px" }}>
          <SizeChartTable
            sizeTable={SizeChart}
            SelectedVariant={SelectedVariant}
            setSelectedVariant={setSelectedVariant}
            isMobile={isMobile}
          />
        </Col>

        {/* Size Chart Description */}
        {isPret ? (
          <Col
            className="border-line-20"
            style={{ padding: "10px 20px", backgroundColor: "white" }}
          >
            <Space direction="vertical">
              <Text className="font14" style={{ fontWeight: "800" }}>
                Length, Optional Full Sleeves, Trouser Length & Bottom is Per
                Catalog Design
              </Text>
              <Text className="font14">
                *If you would like any changes in length, please make sure to
                email us after you place an order.
              </Text>
            </Space>
          </Col>
        ) : null}
        {/* <div
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            padding: "20px 5px",
          }}
        >
          {ProductDetail?.sold_out === false ? (
            <Row
              justify="space-between"
              style={{
                width: isDesktop ? "75%" : "95%",
                backgroundColor: "white",
                margin: "auto",
              }}
              className="bottom-cart-buttons-navigation ProductPage "
            >
              <Col
                style={{ alignSelf: "center" }}
                span={8}
                className="ProductDetailPanel"
              >
                <Space
                  className="width100"
                  style={{ justifyContent: "center" }}
                >
                  <InputNumber
                    className="MobileQuantityPicker MobileQuantityPickerSoldOut"
                    type="number"
                    readOnly={true}
                    controls={false}
                    value={ProductQuantity}
                    placeholder={ProductQuantity}
                    defaultValue={ProductQuantity}
                    addonBefore={
                      <Text
                        className="cursorPointer"
                        onClick={() => quantityDecrement()}
                      >
                        -
                      </Text>
                    }
                    addonAfter={
                      <Text
                        className="cursorPointer"
                        onClick={() => quantityIncrement()}
                      >
                        +
                      </Text>
                    }
                  />
                </Space>
              </Col>

              <Col style={{ alignSelf: "center" }} span={7}>
                {SelectedVariant ? (
                  <Col flex="0 0 auto">
                    {removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.discounted_price
                    ) <=
                    removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.original_price
                    ) ? (
                      <Row
                        gutter={[10, 0]}
                        className="flexColumn "
                        style={{ overflow: "hidden", justifyContent: "center" }}
                      >
                        <Col flex="0 0 auto">
                          <Text className="font11 mobilePriceRed">
                            {defaultCurrency}
                            &nbsp;
                          </Text>
                          <Text
                            className={`price mobilePriceRed priceItemMobile ${
                              isMobile ? "font14Bold" : "font16Bold"
                            }`}
                          >
                            {handleProductDiscountedPrice()}
                          </Text>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                ) : (
                  <Col flex="0 0 auto">
                    {removeCommasAndConvertToNumber(
                      availableVariants?.variant_detail?.discounted_price
                    ) <=
                    removeCommasAndConvertToNumber(
                      availableVariants?.variant_detail?.original_price
                    ) ? (
                      <Row
                        gutter={[10, 0]}
                        className="flexColumn"
                        style={{ overflow: "hidden" }}
                      >
                        <Col flex="0 0 auto">
                          <Text className="font11 mobilePriceRed ">
                            {defaultCurrency}
                            &nbsp;
                          </Text>
                          <Text
                            className={`${
                              isMobile ? "font14Bold" : "font16Bold"
                            } price mobilePriceRed priceItemMobile`}
                          >
                            {ProductDetail?.is_stitch
                              ? handleProductDiscountedPrice()
                              : availableVariants?.variant_detail
                                  ?.discounted_price}
                          </Text>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                )}
              </Col>

              <Col
                span={9}
                alignSelf="center"
                // span={12}
                width="60%"
              >
                <DynamicButton
                  width="100%"
                  type="primary"
                  name={
                    addToCartLoader
                      ? "Adding..."
                      : viewBagItemsState
                      ? "View Bag"
                      : ProductDetail?.pre_order
                      ? "Pre Book"
                      : "Add to Bag"
                  }
                  className="primary_btn poppinsMedium no-gap"
                  function={showDrawersizechart}
                  // function={addProductToCart}

                  // icon={<HiOutlineShoppingBag color="white" />}
                  icon={
                    // <LoadingOutlined style={{ fontSize: 24 }} spin />
                    !addToCartLoader ? (
                      <NextImage
                        height={20}
                        width={20}
                        src="/Handbag-1.svg"
                        style={{
                          marginRight: "-7px",
                          marginBottom: "2px",
                        }}
                      />
                    ) : (
                      // <img src={handBag.src} height="auto" width="auto" />
                      <Spin
                        indicator={
                          <RedoOutlined
                            spin
                            className="spinner-redo-white"
                            fontSize={"20px"}
                            style={{
                              color: "white",
                            }}
                          />
                        }
                      />
                    )
                  }
                  style={{
                    height: "40px",
                    borderRadius: "3px",
                    fontWeight: 600,
                    // textTransform: "capitalize",
                    fontSize: isDesktop ? "16px" : "10px",
                    backgroundColor: !addToCartLoader
                      ? viewBagItemsState
                        ? "black"
                        : "#FF0D50"
                      : "#FF0D50",
                  }}
                />
              </Col>
            </Row>
          ) : (
            <Col flex="49%">
              <DynamicButton
                type="primary"
                name="Sold Out"
                disabled={true}
                property="danger"
                className="primary_btn poppinsMedium"
                icon={<HiOutlineShoppingBag color="white" />}
                style={{
                  height: "40px",
                  fontSize: "16px",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              />
            </Col>
          )}

          <SizeChartBottomStitchedDrawer
            ProductDetail={ProductDetail}
            SelectedVariant={SelectedVariant}
            setSelectedVariant={setSelectedVariant}
            LineItemAddons={LineItemAddons}
            setLineItemAddons={setLineItemAddons}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            showDrawer={showDrawersizechart}
            addProduct={addProduct}
            setViewBagItemsState={setViewBagItemsState}
            pushedVariants={pushedVariants}
            setPushedVariants={setPushedVariants}
            handleOptionChange={handleOptionChange}
            setaddToCartLoader={setaddToCartLoader}
            selectedValueFromOptions={selectedValueFromOptions}
            setSelectedValueFromOptions={setSelectedValueFromOptions}
          />
        </div> */}

        {/* Measure Yourself */}
        <Col className="border-line-20" style={{ backgroundColor: "white" }}>
          <Row style={{ padding: "20px 0px 0px 20px" }}>
            <Col span={24}>
              <Text
                className={isMobile ? "font14" : "font16"}
                style={{ fontWeight: "600" }}
              >
                How to Measure Yourself
              </Text>
            </Col>
            <Col span={24}>
              <Text className={isMobile ? "font14" : "font16"}>
                Attached is a size guide to help you
              </Text>
            </Col>
            <Col
              span={24}
              className="flexCenter"
              style={{ marginTop: "55px", marginBottom: "23px" }}
            >
              <NextImage src={img} alt="sizechart" />
            </Col>
          </Row>
        </Col>
      </Drawer>
    </Col>
    //  </>
    // </div>
  );
}
